import { TabsChangeEventDetail } from '@platform-ui-kit/components-library/components'
import { WppTab, WppTypography, WppTabs, WppCard } from '@platform-ui-kit/components-library-react'
import { MouseEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useToggle } from 'react-use'

import { DropdownToolbar } from 'components/common/dropdownToolbar/DropdownToolbar'
import { FeaturesSideModal } from 'components/common/featuresSideModal/FeaturesSideModal'
import { Flex } from 'components/common/flex/Flex'
import { LargeStepCard } from 'components/common/largeStepCard/LargeStepCard'
import { SmallStepCard } from 'components/common/smallStepCard/SmallStepCard'
import { Feature } from 'pages/liveplan/LiveplanPage'
import styles from 'pages/step/StepPage.module.scss'
import { routesManager } from 'utils/routesManager'

import { data, features, livePlanCards, stepRightNavigation, stepTabs } from '../../data'

export const StepPage = () => {
  const param = useParams()
  const navigate = useNavigate()
  const stepNumber = Number(param.stepId)
  const stepData = data.steps[stepNumber - 1]
  const stepRightNavigationData = stepRightNavigation[stepNumber - 1]
  const stepTabsData = stepTabs[stepNumber - 1]
  const [currentTab, setCurrentTab] = useState('')
  const [selectedFeature, setSelectedFeature] = useState<Feature | null>(null)
  const [isOpen, setIsOpen] = useToggle(false)

  useEffect(() => {
    const defaultTab = stepTabs[stepNumber - 1][0].title
    setCurrentTab(defaultTab)
  }, [stepNumber])

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setCurrentTab(event.detail.value)
  }
  const handleClick = (event: MouseEvent, stepNumber?: number) => {
    event.preventDefault()
    if (stepNumber) navigate(routesManager.steps.step.getURL({ id: stepNumber }))
  }

  const handleLivePlanClick = (event: MouseEvent, stepNumber?: number) => {
    event.preventDefault()
    if (stepNumber) {
      setSelectedFeature(features[stepNumber - 1] as unknown as Feature)
      setIsOpen(true)
    }
  }

  return (
    <>
      <div>
        <LargeStepCard
          title={`${stepData.cardTitle}: ${stepData.cardSubtitle}`}
          description={stepData.cardDescription}
          color={stepData.cardColor}
          image={stepData.cardImage}
          stepNumber={stepNumber}
        />
      </div>

      <div className={styles.pageContent}>
        <WppTypography className={styles.title} type="l-strong" tag="h3">
          {stepData.title}
        </WppTypography>

        <Flex>
          <div className={styles.content}>
            <WppCard className={styles.tabs}>
              <WppTabs value={currentTab} onWppChange={handleTabChange}>
                {stepTabsData.map(tab => (
                  <WppTab value={tab.title} key={tab.title}>
                    {tab.title}
                  </WppTab>
                ))}
              </WppTabs>
              <>
                {stepTabsData.map(tab => {
                  if (tab.title === currentTab)
                    return (
                      <div className={styles.tabContent} key={tab.title}>
                        {tab.value}
                      </div>
                    )
                  else return undefined
                })}
              </>
            </WppCard>
            {stepData.livePlanCards && (
              <>
                <WppTypography className={styles.title} type="l-strong" tag="h3">
                  How does it work on LivePlan?
                </WppTypography>

                <Flex justify="start" gap={30} style={{ width: 250 }}>
                  {livePlanCards
                    .filter(card => stepData.livePlanCards.includes(card.id))
                    .map(card => (
                      <SmallStepCard
                        key={card.title}
                        title={card.title}
                        image={card.image}
                        handleClick={handleLivePlanClick}
                        stepNumber={card.id}
                      />
                    ))}
                </Flex>
                {selectedFeature && (
                  <FeaturesSideModal
                    title={selectedFeature?.title}
                    content={selectedFeature.content}
                    image={selectedFeature.image}
                    isOpen={isOpen}
                    handleCloseModal={() => setIsOpen(false)}
                    handleOpenModal={() => setIsOpen(true)}
                  />
                )}
              </>
            )}
            {stepNumber < 5 && (
              <>
                <WppTypography className={styles.title} type="l-strong" tag="h3">
                  Continue the journey
                </WppTypography>

                <Flex
                  justify="start"
                  gap={30}
                  className={styles.paddingBottom}
                  style={{ width: (5 - stepNumber) * 280 }}
                >
                  {data.steps.map(step => {
                    if (step.id > stepNumber)
                      return (
                        <SmallStepCard
                          key={step.cardTitle}
                          title={step.cardTitle}
                          subtitle={step.cardSubtitle}
                          image={step.cardImage}
                          color={step.cardColor}
                          stepNumber={step.id}
                          handleClick={handleClick}
                        />
                      )
                    else return undefined
                  })}
                </Flex>
              </>
            )}
          </div>

          <div className={styles.rightNavigation}>
            {stepRightNavigationData.map(nav => (
              <DropdownToolbar
                key={nav.title}
                title={nav.title}
                value={nav.value}
                buttons={nav.buttons}
                opened={nav.opened}
              />
            ))}
          </div>
        </Flex>
      </div>
    </>
  )
}
