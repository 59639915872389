// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XezlE{--page-left-right-padding: 38px;--page-top-bottom-padding: 25px;padding:var(--page-top-bottom-padding) var(--page-left-right-padding)}.nPXEm{margin-bottom:25px}.ABHe5{display:block;margin:29px 0}.x2Hog{display:block}.lhZT_{margin:50px 0}.IBMLB{margin:32px 0}.IBMLB::part(card){padding-right:0;padding-left:0;background:rgba(0,0,0,0);box-shadow:none}.pl23I{border-bottom:var(--wpp-border-width-s) solid rgba(0,0,0,.2)}.tbQQs{margin:auto 0}.a0p7n{margin-bottom:35px}.GZda_{width:40%}.tLEhH{width:60%}.NJbJ6{margin-top:auto;text-align:end}", "",{"version":3,"sources":["webpack://./src/pages/evaluator/EvaluatorToolsPage.module.scss"],"names":[],"mappings":"AAAA,OACE,+BAAA,CACA,+BAAA,CAEA,qEAAA,CAGF,OACE,kBAAA,CAGF,OACE,aAAA,CACA,aAAA,CAGF,OACE,aAAA,CAGF,OACE,aAAA,CAGF,OACE,aAAA,CAEA,mBACE,eAAA,CACA,cAAA,CACA,wBAAA,CACA,eAAA,CAIJ,OACE,4DAAA,CAGF,OACE,aAAA,CAGF,OACE,kBAAA,CAGF,OACE,SAAA,CAGF,OACE,SAAA,CAGF,OACE,eAAA,CACA,cAAA","sourcesContent":[".pageContent {\n  --page-left-right-padding: 38px;\n  --page-top-bottom-padding: 25px;\n\n  padding: var(--page-top-bottom-padding) var(--page-left-right-padding);\n}\n\n.breadcrumb {\n  margin-bottom: 25px;\n}\n\n.title {\n  display: block;\n  margin: 29px 0;\n}\n\n.cardDescription {\n  display: block;\n}\n\n.tools {\n  margin: 50px 0;\n}\n\n.cards {\n  margin: 32px 0;\n\n  &::part(card) {\n    padding-right: 0;\n    padding-left: 0;\n    background: transparent;\n    box-shadow: none;\n  }\n}\n\n.borderBottom {\n  border-bottom: var(--wpp-border-width-s) solid rgb(0 0 0 / 20%);\n}\n\n.dashboardItemAutoMargin {\n  margin: auto 0;\n}\n\n.paddingBottom {\n  margin-bottom: 35px;\n}\n\n.items40 {\n  width: 40%;\n}\n\n.items60 {\n  width: 60%;\n}\n\n.processBtn {\n  margin-top: auto;\n  text-align: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContent": "XezlE",
	"breadcrumb": "nPXEm",
	"title": "ABHe5",
	"cardDescription": "x2Hog",
	"tools": "lhZT_",
	"cards": "IBMLB",
	"borderBottom": "pl23I",
	"dashboardItemAutoMargin": "tbQQs",
	"paddingBottom": "a0p7n",
	"items40": "GZda_",
	"items60": "tLEhH",
	"processBtn": "NJbJ6"
};
export default ___CSS_LOADER_EXPORT___;
