import { WppButton, WppCard, WppSideModal, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { BenefitsSideModal } from 'components/common/benefitsModal/BenefitsSideModal'
import { CategorizationSideModal } from 'components/common/categorizationModal/CategorizationSideModal'
import styles from 'components/common/evaluatorToolsCard/EvaluatorToolsCard.module.scss'
import { routesManager } from 'utils/routesManager'

export interface Props {
  title: string
  image: string
  description: string
  button: string
  id: string
}

export const EvaluatorToolsCard = ({ title, image, description, button, id }: Props) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const path =
    id === 'categorisation'
      ? matchPath(routesManager.evaluatorTools.categorisation.pattern, pathname) !== null
      : matchPath(routesManager.evaluatorTools.benefits.pattern, pathname) !== null
  const [isOpen, setIsOpened] = useState(path)

  const handleOpenModal = () => {
    setIsOpened(true)
  }

  const handleCloseModal = () => {
    setIsOpened(false)
    setTimeout(() => {
      navigate(routesManager.evaluatorTools.landing.getURL())
    }, 1000)
  }

  return (
    <>
      <WppCard className={styles.card}>
        <div className={styles.imageDiv}>
          <img src={require(`assets/${image}.png`)} alt={title} />
        </div>
        <div className={styles.content}>
          <WppTypography className={styles.cardTitle} type="l-strong" tag="h4">
            {title}
          </WppTypography>
          <WppTypography className={styles.description} type="s-body" tag="span">
            {description}
          </WppTypography>
        </div>
        <WppButton size="s" className={styles.button} onClick={handleOpenModal}>
          {button}
        </WppButton>
      </WppCard>
      <WppSideModal
        open={isOpen}
        onWppSideModalClose={handleCloseModal}
        onWppSideModalOpen={handleOpenModal}
        size="2xl"
        className={id === 'categorisation' ? styles.modalCategorisation : styles.modalBenefits}
      >
        {id === 'benefits' && <BenefitsSideModal />}
        {id === 'categorisation' && <CategorizationSideModal />}
      </WppSideModal>
    </>
  )
}
